import { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'store';

// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';

import { getClinicTaskCount } from 'store/slices/account';
import { getAlertsStats } from 'store/slices/vitalsAlerts';
import { hasClinicManagerGrantOrHigherAccess, hasTeamsManagementOrHigher } from 'access-control/sdk';
import { getMenuItems } from './items';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const dispatch = useDispatch();

    const accountData = useSelector((state) => state.account);
    const userTaskData = useSelector((state) => state.account.tasks);
    const vitalsCountData = useSelector((state) => state.vitalsAlerts.count);

    useEffect(() => {
        dispatch(getClinicTaskCount(accountData.selectedClinic));
        dispatch(getAlertsStats(accountData.selectedClinic));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountData.selectedClinic]);

    const isCurrentUserOrgAdmin = accountData.organizationMeta?.grants?.ORG_ADMIN === true;
    const isClinicManagerOrHigher = hasClinicManagerGrantOrHigherAccess(accountData);
    const isTeamsManagerOrHigher = hasTeamsManagementOrHigher(accountData);

    const menuItems = getMenuItems(
        accountData.selectedClinic,
        userTaskData.activeAssignedToMe?.clinic,
        userTaskData.activeAssignedToMe?.protocol,
        isCurrentUserOrgAdmin,
        isClinicManagerOrHigher,
        isTeamsManagerOrHigher,
        vitalsCountData.clinic
    );

    const navItems = menuItems.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default memo(MenuList);
