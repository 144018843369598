import axios from 'utils/axios';

export async function getClinicList() {
    try {
        const response = await axios.get('/api/org-id/{orgId}/clinics/list');
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            throw e;
        }
        throw new Error('unknown error');
    }
}
