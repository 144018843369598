import axios from 'utils/axios';
import { convertOrgOptionsToRole } from 'utils/permissions-management';

export async function postUserNew(data) {
    try {
        const orgRole = convertOrgOptionsToRole(data.orgAdmin, data.orgTeamsManagement);
        const response = await axios.post(`/api/org-id/{orgId}/user/new`, {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            clinicMapping: data.clinicMapping,
            orgRole
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function fetchUsersPermissionList() {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/users/user-permissions-list`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function getUserDetail(userId) {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/user/${userId}/detail`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function changePassword(userId, password) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/user/${userId}/change-password`, {
            password
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function changeClinicMapping(userId, clinicMapping, orgRole) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/user/${userId}/access-config-mapping`, {
            clinicMapping,
            orgRole
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function changeUserStatus(userId, disabled) {
    try {
        const response = await axios.post(`/api/org-id/{orgId}/user/${userId}/status`, {
            disabled
        });
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            return e;
        }
        return {
            message: 'unknown error'
        };
    }
}

export async function fetchActiveOrgMembersList() {
    try {
        const response = await axios.get(`/api/org-id/{orgId}/users/organization-members-list`);
        return response;
    } catch (e) {
        if (e && e.statusCode && e.message) {
            throw e;
        }
        throw new Error('unknown error');
    }
}
