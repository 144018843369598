import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../index';

import * as clinicsAPI from 'api/clinics';

const initialState = {
    list: {
        error: false,
        loaded: false,
        data: []
    }
};

const slice = createSlice({
    name: 'clinics',
    initialState,
    reducers: {
        setClinicListError(state) {
            state.list.error = true;
            state.list.loaded = true;
        },
        setClinicList(state, action) {
            state.list.error = false;
            state.list.loaded = true;
            state.list.data = action.payload;
        },
        resetClinicList(state) {
            state.list = initialState.list;
        }
    }
});

export default slice.reducer;

export function resetClinicList() {
    dispatch(slice.actions.resetClinicList());
}

export function getClinicList() {
    return async () => {
        try {
            const response = await clinicsAPI.getClinicList();
            dispatch(slice.actions.setClinicList(response.data.clinics));
        } catch (error) {
            dispatch(slice.actions.setClinicListError(error));
        }
    };
}
